import React, { useState } from 'react';
import useTitle from '../../hooks/useTitle';
import { Box, Button, Flex, HStack, Table, Tbody, Tr, Td, Text, VStack, Stack, Tfoot, Thead, Th, useToast, Link } from '@chakra-ui/react';
import { useGetTasksQuery, useUpdateTaskDownloadMutation, useUpdateTaskSendEmailMutation } from '../tasks/tasksApiSlice';
import { useParams } from 'react-router-dom';
import { converTime, formatPhoneNumber, getPDFTasks, getPPTTasks, pathFile, sendWhatsappDirect } from '../../utils/Functions';
import parse from 'html-react-parser';
import './stepper.styles.css'
import BodyTemplate from '../../components/BodyTemplate/BodyTemplate';
import useAuth from '../../hooks/useAuth';
import { useGetProjectsQuery } from '../projects/projectsApiSlice';
import { TemplateWhatsAppID } from '../../utils/Constant';

const DetailReport = () => {
  useTitle('Wika Engineering Report System - Detail Report');

  const [updateTaskDownload, {
    isLoading,
  }] = useUpdateTaskDownloadMutation();

  const [updateTaskSendEmail, {
    isLoading: isLoadingSendEmail,
  }] = useUpdateTaskSendEmailMutation();

  const {
    data: projectsList,
  } = useGetProjectsQuery("projectsList", {
    pollingInterval: 60000,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });

  const { name, phone, email } = useAuth()
  const { id } = useParams()
  const toast = useToast()

  const { task } = useGetTasksQuery("tasksList", {
    selectFromResult: ({ data }) => ({
        task: data?.entities[id]
    }),
  })

  const [searchHistoryDownload, setSearchHistoryDownload] = useState("");
  const [isLoadingPDF, setIsLoadingPDF] = useState(false);
  const [isLoadingPPT, setIsLoadingPPT] = useState(false);

  const filterAttachment = task?.attachment.filter((file) => file !== "")

  const handleDownloadPPT = async () => {
    setIsLoadingPPT(true)

    const toastId = toast({
      title: 'Downloading...',
      description: 'Sedang melakukan Download Presentasi.',
      status: 'info',
      duration: null,
      isClosable: true,
      position: 'top',
    });

    try {
      await getPPTTasks(task)

      toast.update(toastId, {
        title: 'Download Complete',
        description: 'Presentasi Telah berhasil di download.',
        status: 'success',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } catch (error) {
      setIsLoadingPPT(false)
      toast.update(toastId, {
        title: 'Download Failed',
        description: 'Ada masalah ketika download presentasi.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    } finally {
      setIsLoadingPPT(false)
    }
  }

  const handleDownloadPDF = async () => {
    setIsLoadingPDF(true)
    const toastId = toast({
        title: 'Downloading...',
        description: 'Sedang melakukan Download Report.',
        status: 'info',
        duration: null,
        isClosable: true,
        position: 'top',
      });

    const payload = {
      id: task._id,
      email,
      name,
    }

    try {
      await updateTaskDownload({
        ...payload,
      }).then(async (res) => {
        if (res) {
          try {
          await getPDFTasks(task)

          toast.update(toastId, {
            title: 'Download Complete',
            description: 'Report Telah berhasil di download.',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
          } catch (error) {
            toast.update(toastId, {
              title: 'Download Failed',
              description: 'Ada masalah ketika download report.',
              status: 'error',
              duration: 5000,
              isClosable: true,
              position: 'top',
            });
          } finally {
            setIsLoadingPDF(false)
          }
        } else {
          setIsLoadingPDF(false)
        }
      }).catch((err) => {
        setIsLoadingPDF(false)
        console.log("Error: ", err)
      })
    } catch (error) {
      setIsLoadingPDF(false)
      console.error(error)
    }
  }
  
  const sendWhatsApp = () => {
    const project = projectsList?.ids.map((projectId) => projectsList.entities[projectId]).find((project) => project.project === task.projectName)

    for (let i = 0; i < project.pic.length; i++) {
      const pic = project.pic[i]

      if (pic.name) {
        return sendWhatsappDirect({
          name,
          to: formatPhoneNumber(pic.phone),
          template: TemplateWhatsAppID.sendReport,
          variables: {
            nama_pic_project: pic.name,
            nama_report: task.taskName,
            link_download: `${process.env.REACT_APP_WIKA_ERS_URL || 'http://localhost:3000'}/verify/${task._id}`,
            nama_project: task.projectName,
          },
          file: 'unfile',
        })
      }
    }
        
    return toast({
      title: "Kirim WhatsApp",
      description: `Berhasil mengirimkan pesan WhatsApp ${phone}`,
      status: "info",
      duration: 9000,
      isClosable: true,
      position: 'top',
    })

  }

  const sendEmail = () => {
    const project = projectsList?.ids.map((projectId) => projectsList.entities[projectId]).find((project) => project.project === task.projectName)

    for (let i = 0; i < project.pic.length; i++) {
      const pic = project.pic[i]
      const email = project.email
      const subject = `Halo, ${pic?.name ?? ""} Berikut adalah Hasil dari Project ${project?.project ?? ""}`
      const message = `Silahkan di download melalui link ini`
      const id = task._id

      if (pic.name) {
        updateTaskSendEmail({
          id, email, subject, message
        })
      }
    }
        
    return toast({
      title: "Kirim Email",
      description: `Berhasil mengirimkan email ke PIC`,
      status: "info",
      duration: 9000,
      isClosable: true,
      position: 'top',
    })
  }

  return (
    <>
      <HStack>
          <Text
              fontSize="medium"
              color="gray.800"
              fontWeight="300"
              mb={4}
          >Report 
          </Text>
          <Text
              fontSize="medium"
              color="gray.800"
              mb={4}
              fontWeight="600"
          >/ Report Activity / {task?.taskName}</Text>
      </HStack>
      <HStack
          spacing={4}
          justify="space-between"
          align="start"
      >
        <Box
          p={6}
          mt={4}
          mb={4}
          borderRadius="md"
          boxShadow="md"
          bg="white"
          w="30%"
          position="relative"
        >
          <Text
            fontSize="xl"
            color="gray.800"
            fontWeight="600"
            mb={4}
          >Report Activity</Text>
          <VStack align="stretch" position="relative">
          {task?.report?.map((item, index) => (
            <Box key={index} position="relative" pb={index !== task?.report?.length - 1 ? 10 : 0}>
              <Box className="dot" />
              <Box className="line" />
              <Box className="step">
                <Text fontSize="sm" color="gray.500">{converTime(item.date, true)}</Text>
                <Text fontSize="md" fontWeight="bold" color="blue.500">{item.description}</Text>
                <Text fontSize="sm" fontWeight="medium" color="gray.500">by {item.updatedBy.role} - {item.updatedBy.name}</Text>
              </Box>
            </Box>
          ))}
        </VStack>
          </Box>
          <VStack
            align="start"
            w="70%"
          >
          <Box
            p={6}
            mt={4}
            mb={4}
            borderRadius="md"
            boxShadow="md"
            bg="white"
            w="100%"
          >
          <Text
            fontSize="xl"
            color="gray.800"
            fontWeight="600"
            mb={4}
          >Project Description</Text>
          <Stack pl={8} pr={8} mt={4} mb={4}>
            <Text fontSize="medium" fontWeight="b">Nama Proyek :</Text>
            <Text>{task?.taskName}</Text>
          </Stack>
          <Stack pl={8} pr={8} mt={4} mb={4}>
            <Text fontSize="medium" fontWeight="b">Lokasi Proyek :</Text>
            <Text>{task?.projectLocation}</Text>
          </Stack>
          <Stack pl={8} pr={8} mt={4} mb={4}>
            <Text fontSize="medium" fontWeight="b">Tipe Proyek :</Text>
            <Text>{task?.projectType}</Text>
          </Stack>
          <Stack pl={8} pr={8} mt={4} mb={4}>
            <Text fontSize="medium" fontWeight="b">Nama Report :</Text>
            <Text>{task?.projectName}</Text>
          </Stack>
          {task?.descriptionGeneral && (
            <Stack pl={8} pr={8} mt={4} mb={4}>
              <Text fontSize="medium" fontWeight="b">Data Umum Proyek / Pendahuluan :</Text>
              <Text>{task?.descriptionGeneral && parse(task?.descriptionGeneral)}</Text>
            </Stack>
          )}
          {task?.planAnalysis && (
            <Stack  pl={8} pr={8} mt={4} mb={4}>
              <Text fontSize="medium" fontWeight="b">Lingkup Pekerjaan :</Text>
              <Text>{task?.planAnalysis && parse(task?.planAnalysis)}</Text>
            </Stack>
          )}
          {task?.kriteriaPerencaan && (
            <Stack  pl={8} pr={8} mt={4} mb={4}>
              <Text fontSize="medium" fontWeight="b">Kriteria Perencanaan :</Text>
              <Text p={4}>{task?.kriteriaPerencaan && parse(task?.kriteriaPerencaan)}</Text>
            </Stack>
          )}
          {task?.standardUsed.length > 0 && (
            <Stack  pl={8} pr={8} mt={4} mb={4}>
              <Text fontSize="medium" fontWeight="b">Standar Code :</Text>
              <Text>{task?.standardUsed?.map((standard, index) => (
                  `${standard.text}${index === task.standardUsed.length - 1 ? "" : ", "}`
              ))}</Text>
            </Stack>
          )}
          {task?.formulation?.length > 0 && (
            <Stack  pl={8} pr={8} mt={4} mb={4}>
              <Text fontSize="medium" fontWeight="b">Data Acuan :</Text>
              <Text>{task?.formulation?.map((formulation, index) => (
                  `${formulation.text}${index === task.formulation.length - 1 ? "" : ", "}`
              ))}</Text>
            </Stack>
          )}
          {task?.analysis?.length > 0 && (
            <Stack  pl={8} pr={8} mt={4} mb={4}>
              <Text fontSize="medium" fontWeight="b">Analisis :</Text>
              {task?.analysis && Array.isArray(task.analysis) && task.analysis.map((item, index) => (
                  <Stack key={index} mr="1">
                      <Text fontWeight="bold">{item.analysisName}</Text>
                      <Text>{parse(item.analysis)}</Text>
                  </Stack>
              ))}
            </Stack>
          )}
          {task?.resume?.length > 0 && (
            <Stack  pl={8} pr={8} mt={4} mb={4}>
              <Text fontSize="medium" fontWeight="b">Resume :</Text>
              {task?.resume && Array.isArray(task.resume) && task.resume.map((item, index) => (
                  <Stack key={index} mr="1">
                      <Text fontWeight="bold">{item.resumeName}</Text>
                      <Text>{parse(item.resume)}</Text>
                  </Stack>
              ))}
            </Stack>
          )}
          {task?.conclusion && (
            <Stack  pl={8} pr={8} mt={4} mb={4}>
              <Text fontSize="medium" fontWeight="b">Kesimpulan & Saran :</Text>
              <Text>{task?.conclusion && parse(task?.conclusion)}</Text>
            </Stack>
          )}
          {task?.attachment.length > 0 && (
            <Stack  pl={8} pr={8} mt={4} mb={4}>
              <Text fontSize="medium" fontWeight="b">Lampiran :</Text>
              {filterAttachment.map((file, index) => (
                  <>
                    {file.includes("https://178.16.139.201:9000") || file.includes("http://178.16.139.201:9000") ? (
                      <Link
                        href={file.includes("http://178.16.139.201:9000") ? file.replace('http://', 'https://') : file}
                        key={index}
                        isExternal={true}
                        onClick={(e) => {
                          e.preventDefault();
                          window.open(pathFile(file), '_blank', 'noopener,noreferrer');
                        }}
                      >
                        <Text
                          fontSize={"medium"}
                          color="blue.500"
                          cursor="pointer"
                        >
                          {index === 0 ? `${index + 1}.` : " "} {file && `${file.replace('http://178.16.139.201:9000/ers-central-s3/uploads/', '.')}${index === filterAttachment.length - 1 ? "" : ", "}`}
                        </Text>
                      </Link>
                    ) : (
                      <Text
                        fontSize={"medium"}
                        color="blue.500"
                        onClick={() => {
                          window.open(pathFile(file), "_blank")
                        }}
                        key={index}
                        cursor="pointer"
                      >
                        {index === 0 ? `${index + 1}.` : " "} {file && `${file.replace('http://178.16.139.201:9000/ers-central-s3/uploads/', '')}${index === filterAttachment.length - 1 ? "" : ", "}`}
                      </Text>
                    )}
                  </>
                    ))}
            </Stack>
          )}
          {task?.report?.find((update) => ["Revision I", "Revision II"].includes(update.status)) && (
            <Stack  pl={8} pr={8} mt={4} mb={4}>
              <Text fontSize="medium" fontWeight="b">Revisi Notes :</Text>
              {task?.report?.map((update, i) => update.notes && (
                <Stack key={i}>
                  {!update.attachment && update.attachment.length === 0 ? (
                    <Text>
                      {update.status} - {update.notes}
                    </Text>
                  ) : (
                    <Stack
                      flexDirection={"row"}
                    >
                      <Stack>
                        <Text as="b">
                          {update.status} :
                        </Text>
                      </Stack>
                      <Stack>
                        <Text>{update.notes}</Text>
                        {update.attachment && update.attachment.filter((file) => file !== "").map((file, index) => (
                          <Text
                            fontSize={"medium"}
                            color="blue.500"
                            onClick={() => {
                              window.open(pathFile(file), "_blank")
                            }}
                            key={index}
                            cursor="pointer"
                          >
                            {index === 0 ? `${index + 1}.` : " "} {file && `${file}${index === update.attachment.length - 1 ? "" : ", "}`}
                          </Text>
                        ))}
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              ))}
            </Stack>
          )}
          <Stack p={8}>
            <Flex
              justify="space-between"
              align="center"
              w="full"
            >
                {task?.statusTask === "Completed" && (
                <>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    w="full"
                    p={6}
                    mr={2}
                    onClick={handleDownloadPDF}
                    disabled={isLoadingPDF}
                    isLoading={isLoadingPDF}
                  >
                    Download Report
                  </Button>
                  <Button
                    colorScheme="red"
                    size="sm"
                    w="full"
                    p={6}
                    mr={2}
                    onClick={handleDownloadPPT}
                    disabled={isLoadingPPT}
                    isLoading={isLoadingPPT}
                  >
                    Presentation
                  </Button>
                  <Button
                    colorScheme="green"
                    size="sm"
                    w="full"
                    p={6}
                    mr={2}
                    onClick={() => {
                      const mainPath = process.env.REACT_APP_WIKA_ERS_URL || "http://localhost:3000";

                      window.open(`${mainPath}/verify/${task.id}`, "_blank")
                    }}
                  >
                    Verify Report
                  </Button>
                  <Button
                    colorScheme="whatsapp"
                    size="sm"
                    w="full"
                    p={6}
                    mr={2}
                    onClick={sendWhatsApp}
                  >
                    Send WhatsApp
                  </Button>
                  <Button
                    colorScheme="telegram"
                    size="sm"
                    w="full"
                    p={6}
                    onClick={sendEmail}
                  >
                    Send Email
                  </Button>
                </>
              )}
            </Flex>
          </Stack>
        </Box>
        {(task?.statusTask === "Completed" && task?.historyDownload) && (
          <Stack
            w="100%"
            mt={4}
          >
            <BodyTemplate
              documentTitle="History Download"
              pageTitle="History Download"
              searchBarPlaceHolder="History Download"
              useCreate={false}
              searchValue={searchHistoryDownload}
              isHistoryDownload={true}
              setSearchValue={setSearchHistoryDownload}
            >
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>No</Th>
                    <Th>Nama</Th>
                    <Th>Email</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {task?.historyDownload.map((item, index) => (
                    <Tr>
                      <Td>{index +1}</Td>
                      <Td>{item.name}</Td>
                      <Td>
                        <Text color="blue.500" cursor="pointer">
                          {item.email}
                        </Text>
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </BodyTemplate>
          </Stack>
        )}
        </VStack>
      </HStack>
    </>
  );
};

export default DetailReport;
