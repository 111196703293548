import { useParams } from 'react-router-dom'
import { useGetTasksQuery, useUpdateTaskMutation } from './tasksApiSlice'
import { useGetUsersQuery } from '../users/usersApiSlice'
import useAuth from '../../hooks/useAuth'
import PulseLoader from 'react-spinners/PulseLoader'
import useTitle from '../../hooks/useTitle'
import { Box, Button, FormControl, FormLabel, HStack, Icon, Input, InputGroup, InputRightAddon, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Spacer, Stack, Table, Tbody, Text, Textarea, Tooltip, VStack, useToast } from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css';
import { editorFormats, editorModules, formatPhoneNumber, pathFile, sendWhatsappDirect, uploadImage } from '../../utils/Functions'
import { AiOutlineFileAdd } from 'react-icons/ai'
import { WithContext as ReactTags } from 'react-tag-input';
import { StandardsUsed, TemplateWhatsAppID } from '../../utils/Constant'
import './common/tags.styles.css'
import parse from 'html-react-parser';
import { InfoOutlineIcon } from '@chakra-ui/icons'
const DetailTask = () => {
    useTitle('Wika Engineering Report System - Detail Task')

    const toast = useToast()
    const { id } = useParams()

    const { isManager, isAdmin, isKoordinator, status, name, username, phone, image } = useAuth()

    const { task } = useGetTasksQuery("tasksList", {
        selectFromResult: ({ data }) => ({
            task: data?.entities[id]
        }),
    })

    const { users } = useGetUsersQuery("usersList", {
        selectFromResult: ({ data }) => ({
            users: data?.ids.map(id => data?.entities[id])
        }),
    })

    const { data: listUsers } = useGetUsersQuery("usersList", {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true,
      });

    const [payload, setPayload] = useState({});
    const [attachment, setAttachment] = useState(task?.attachment || [])
    const [attachmentRevisi, setAttachmentRevisi] = useState([])
    const [isApproveOpen, setIsApproveOpen] = useState(false)
    const [isRevisionOpen, setIsRevisionOpen] = useState(false)
    const [isConfirm, setIsConfirm] = useState(false)
    const [revisionNotes, setRevisionNotes] = useState("")
    const [standardUsedTag, setStandardUsedTag] = useState(task?.standardUsed || [])
    const [formulationTag, setFormulationTag] = useState(task?.formulation || [])
    const [analysisData, setAnalysisData] = useState(task?.analysis || [])
    const [resumeData, setResumeData] = useState(task?.resume || [])
    const [updateTask, {}] = useUpdateTaskMutation()
    const [isLoad, setIsLoad] = useState(false)

    useEffect(() => {
        window.onbeforeunload = function() {
            return true;
        }

        window.onpopstate = function() {
            return true;
        }

        return () => {
            window.onbeforeunload = null
            window.onpopstate = null
        }
    }, [])

    const suggestions = StandardsUsed.map(data => {
        return {
          id: data,
          text: data
        };
      });

      const suggestionsFormulation = StandardsUsed.map(data => {
        return {
          id: data,
          text: data
        };
      });
      
    const KeyCodes = {
        comma: 188,
        enter: 13
    };
    
    const delimiters = [KeyCodes.comma, KeyCodes.enter];

    const handleStandardUsedDelete = (i) => {
        setStandardUsedTag(standardUsedTag.filter((tag, index) => index !== i));
    }

    const handleStandardUsedAddition = tag => {
        setStandardUsedTag((prevTags) => {
            return [...prevTags, tag];
          });
      };
    
      const handleStandardUsedDrag = (tag, currPos, newPos) => {
        const newTags = standardUsedTag.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
    
        // re-render
        setStandardUsedTag(newTags);
      };
    
      const handleStandardUsedClick = index => {
        console.log('The tag at index ' + index + ' was clicked');
      };

      const handleFormulationDelete = (i) => {
        setFormulationTag(formulationTag.filter((tag, index) => index !== i));
    }

    const handleFormulationAddition = tag => {
        setFormulationTag((prevTags) => {
            return [...prevTags, tag];
          });
      };
    
      const handleFormulationDrag = (tag, currPos, newPos) => {
        const newTags = formulationTag.slice();

        newTags.splice(currPos, 1);
        newTags.splice(newPos, 0, tag);
    
        // re-render
        setFormulationTag(newTags);
      };
    
      const handleFormulationClick = index => {
        console.log('The tag at index ' + index + ' was clicked');
      };

    const handleRemoveAttachment = (index) => {
        setAttachment((prev) => {
            if (prev.length === 1 || prev.length === 0) {
            return [];
            }
            return prev.filter((_, i) => i !== index);
        });
    };

    const handleRemoveAttachmentRevisi = (index) => {
        setAttachmentRevisi((prev) => {
            if (prev.length === 1 || prev.length === 0) {
              return [];
            }
            return prev.filter((_, i) => i !== index);
          });
    }

    if (!task || !users?.length) return <PulseLoader color={"#FFF"} />

    const onApprove = () => {
        if (isAdmin) {
            return toast({
                title: 'Not Allowed',
                description: "You are is admin not capable to make decision for approve this.",
                status: 'error',
                duration: 9000,
                isClosable: true,
              })
        }
        setIsApproveOpen(true)
    }

    const onApproveClose = () => {
        setIsApproveOpen(false)
    }

    const onConfirmClose = () => {
        setIsConfirm(false)
    }

    const onApproveHandler = async () => {
        setIsLoad(true)

        const toastId = toast({
            title: 'Please wait...',
            description: 'Approving task report.',
            status: 'info',
            duration: 9000,
            isClosable: true,
            position: 'top',
        })

        let statusLine = ""
        const findOriginator = task.originator.find((user) => user.username === username)
        const unOriginator = task.originator.find((user) => user.username !== username)
        let updatedOriginators = []

        if (findOriginator) {
            if (unOriginator) {
                updatedOriginators = [{
                    ...findOriginator,
                    status: "Approved",
                }, unOriginator]
            } else {
                updatedOriginators = [{
                    ...findOriginator,
                    status: "Approved",
                }]
            }
        } else {
            updatedOriginators = [
                ...task.originator,
                {
                    username,
                    phone,
                    image,
                    status: "Approved",
                }
            ]
        }

        if (isKoordinator) {
            statusLine = {
                koordinator: "Approved"
            }
        }

        if (isManager) {
            statusLine = {
                managerEngineering: "Approved"
            }
        }

        try {
            await updateTask({
                ...task,
                id: task.id,
                originator: updatedOriginators,
                statusLine,
                report: {
                    date: new Date().toISOString(),
                    description: "Task Report Approved",
                    status: "Approved",
                    updatedBy: {
                        role: status,
                        name: name,
                    }
                }
            })

            toast.update(toastId, {
                title: 'Success',
                description: 'Task report has been approved.',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top',
            })
        } catch (error) {
            setIsLoad(false)
            setIsApproveOpen(false)
            window.history.back()

            toast.update(toastId, {
                title: 'Failed',
                description: 'Failed to approve task report.',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top',
            })
        } finally {
            setIsLoad(false)
            setIsApproveOpen(false)
            window.history.back()

            if (status === "Koordinator") {
                const findAssignTo = task.assignTo.map((userId) => ({
                    name: listUsers.entities[userId].name,
                    phone: listUsers.entities[userId].phone,
                }))
    
                await sendWhatsappDirect({
                    name: name,
                    to: formatPhoneNumber('081216839573'),
                    template: TemplateWhatsAppID.sendApproval,
                    variables: {
                        nama_meng: 'Romi Ramadhan',
                        nama_koor: name,
                        nama_report: task.taskName,
                        nama_engineer: `${findAssignTo.map((user) => user.name).join(", ")}`,
                        nama_project: task.projectName,
                    },
                    file: 'unfile',
                })

                toast({
                    title: 'Whatsapp sent successfully',
                    description: 'Task has been sent to Manager Engineering',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                    position: 'top',
                })
            }
            
            // if (status === "Manager") {
            //     const findAssignTo = task.assignTo.map((userId) => ({
            //         name: listUsers.entities[userId].name,
            //         phone: listUsers.entities[userId].phone,
            //     }))
    
            //     await sendWhatsappDirect({
            //         name: name,
            //         to: formatPhoneNumber(phone),
            //         template: TemplateWhatsAppID.sendApproval,
            //         variables: {
            //             nama_meng: task.statusLine.koordinator,
            //             nama_koor: name,
            //             nama_report: task.taskName,
            //             nama_engineer: `${findAssignTo.map((user) => user.name).join(", ")}`,
            //             nama_project: task.projectName,
            //         },
            //         file: 'unfile',
            //     })

            //     toast({
            //         title: 'Whatsapp sent successfully',
            //         description: 'Task has been sent to Koordinator',
            //         status: 'success',
            //         duration: 9000,
            //         isClosable: true,
            //         position: 'top',
            //     })
            // }
        }
    }

    const onRevision = () => {
        if (isAdmin) {
            return toast({
                title: 'Not Allowed',
                description: "You are is admin not capable to make decision for revision this.",
                status: 'error',
                duration: 9000,
                isClosable: true,
              })
        }
        setIsRevisionOpen(true)
    }

    const onRevisionClose = () => {
        setIsRevisionOpen(false)
    }

    const onRevisionHandler = async () => {
        setIsLoad(true)

        const toastId = toast({
            title: 'Please wait...',
            description: 'Revising task report.',
            status: 'info',
            duration: 9000,
            isClosable: true,
            position: 'top',
        })

        let statusLine = ""
        const findOriginator = task.originator.find((user) => user.username === username)
        const unOriginator = task.originator.find((user) => user.username !== username)
        let updatedOriginators = []
        if (findOriginator && ["Open", "Waiting Review"].includes(task.statusTask)) {
            if (unOriginator) {
                updatedOriginators = [{
                    ...findOriginator,
                    status: ["Open", "Waiting Review"].includes(task.statusTask) ? "Revision I" : "Revision II",
                }, unOriginator]
            } else {
                updatedOriginators = [{
                    ...findOriginator,
                    status: ["Open", "Waiting Review"].includes(task.statusTask) ? "Revision I" : "Revision II",
                }]
            }
        } else if (findOriginator && task.statusTask === "Revision I") {
            if (unOriginator) {
                updatedOriginators = [{
                    ...findOriginator,
                    status: "Revision II",
                }, unOriginator]
            } else {
                updatedOriginators = [{
                    ...findOriginator,
                    status: "Revision II",
                }]
            }
        } else if (findOriginator && task.statusTask === "Revision II") {
            if (unOriginator) {
                updatedOriginators = [{
                    ...findOriginator,
                    status: "Approved",
                }, unOriginator]
            } else {
                updatedOriginators = [{
                    ...findOriginator,
                    status: "Approved",
                }]
            }
        } else if (!findOriginator && ["Open", "Waiting Review"].includes(task.statusTask)) {
            updatedOriginators = [
                ...task.originator,
                {
                    username,
                    phone,
                    image,
                    status: "Revision I",
                }
            ]
        } else if (!findOriginator && task.statusTask === "Revision I") {
            updatedOriginators = [
                ...task.originator,
                {
                    username,
                    phone,
                    image,
                    status: "Revision II",
                }
            ]
        } else if (!findOriginator && task.statusTask === "Revision II") {
            updatedOriginators = [
                ...task.originator,
                {
                    username,
                    phone,
                    image,
                    status: "Approved",
                }
            ]
        }

        if (isKoordinator) {
            statusLine = {
                koordinator: ["Open", "Waiting Review"].includes(task.statusTask) ? "Revision I" : task.statusTask === "Revision II" ? "Approved" : "Revision II"
            }
        }

        if (isManager) {
            statusLine = {
                managerEngineering: ["Open", "Waiting Review"].includes(task.statusTask) ? "Revision I" : task.statusTask === "Revision II" ? "Approved" : "Revision II"
            }
        }

        const filterAttachmentRevisi = attachmentRevisi.length ? attachmentRevisi.filter((file) => file !== "") : []

        try {
            await updateTask({
                ...task,
                id: task.id,
                originator: updatedOriginators,
                statusLine,
                report: {
                    date: new Date().toISOString(),
                    description: `${["Open", "Waiting Review"].includes(task.statusTask) ? `Task Report Revisi ${["Open", "Waiting Review"].includes(task.statusTask) ? "I" : "II"}` : "Task Report Approved"}`,
                    notes: revisionNotes,
                    attachment: filterAttachmentRevisi,
                    status: ["Open", "Waiting Review"].includes(task.statusTask) ? "Revision I" : task.statusTask === "Revision II" ? "Approved" : "Revision II",
                    updatedBy: {
                        role: status,
                        name: name,
                    }
                }
            })

            toast.update(toastId, {
                title: 'Success',
                description: 'Task report has been revised.',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top',
            })
        } catch (error) {
            setIsRevisionOpen(false)
            setIsLoad(false)
            
            toast.update(toastId, {
                title: 'Failed',
                description: 'Failed to revise task report.',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top',
            })
        } finally {
            setIsRevisionOpen(false)
            setIsLoad(false)

            const findAssignTo = task.assignTo.map((userId) => ({
                name: listUsers.entities[userId].name,
                phone: listUsers.entities[userId].phone,
            }))
    
            if (findAssignTo.length) {
                for (let i = 0; i < findAssignTo.length; i++) {
                    await sendWhatsappDirect({
                        name: name,
                        to: formatPhoneNumber(findAssignTo[i].phone),
                        template: TemplateWhatsAppID.sendRevision,
                        variables: {
                            nama_engineer: findAssignTo[i].name,
                            nama_report: task.taskName,
                            nama_project: task.projectName,
                        },
                        file: 'unfile',
                    })
                }

                toast({
                    title: 'Whatsapp sent successfully',
                    description: 'Task has been sent to Engineers',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                    position: 'top',
                })
            }
        }
    }

    const handleUpdateTask = async (statusConfirm) => {
        setIsLoad(true)

        const toastId = toast({
            title: 'Please wait...',
            description: 'Updating task report.',
            status: 'info',
            duration: 9000,
            isClosable: true,
            position: 'top',
        })
        
        const {
            projectName,
            projectLocation,
            projectType,
            taskName,
            descriptionGeneral,
            kriteriaPerencaan,
            planAnalysis,
            analysis,
            conclusion,
            resume,
        } = payload

        let updateReport = {}

        if (projectName &&
            projectLocation &&
            projectType &&
            taskName &&
            kriteriaPerencaan &&
            descriptionGeneral &&
            planAnalysis &&
            formulationTag &&
            standardUsedTag &&
            analysis &&
            resume &&
            conclusion && statusConfirm === "Confirm") {
            updateReport = {
                date: new Date().toISOString(),
                description: "Task Report Updated",
                status: "Waiting Approval",
                updatedBy: {
                    role: status,
                    name: name,
                }
            }
        } else {
            updateReport = {
                date: new Date().toISOString(),
                description: "Task Report Updated",
                status: "Open",
                updatedBy: {
                    role: status,
                    name: name,
                }
            }
        }

        const filterAttachment = attachment.length ? attachment.filter((file) => file !== "") : task?.attachment.filter((file) => file !== "")

        try {
            await updateTask({
                ...task,
                id: task.id,
                projectName: projectName || task.projectName,
                projectLocation: projectLocation || task.projectLocation,
                projectType: projectType || task.projectType,
                taskName: taskName || task.taskName,
                kriteriaPerencaan: kriteriaPerencaan || task.kriteriaPerencaan,
                descriptionGeneral: descriptionGeneral || task.descriptionGeneral,
                planAnalysis: planAnalysis || task.planAnalysis,
                formulation: formulationTag || task.formulation,
                standardUsed: standardUsedTag || task.standardUsed,
                analysis: analysisData || task.analysis,
                conclusion: conclusion || task.conclusion,
                resume: resumeData || task.resume,
                attachment: filterAttachment,
                report: updateReport,
                statusProgress: statusConfirm,
            })

            toast.update(toastId, {
                title: 'Success',
                description: 'Task report has been updated.',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top',
            })
        } catch (error) {
            setIsLoad(false)
            window.history.back()

            toast.update(toastId, {
                title: 'Failed',
                description: 'Failed to update task report.',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top',
            })
        } finally {
            setIsLoad(false)
            window.history.back()

            if (statusConfirm === "Confirm") {
                for (let i = 0; i < task.originator.length; i++) {
                    await sendWhatsappDirect({
                        name: name,
                        to: formatPhoneNumber(task.originator[i].phone),
                        template: TemplateWhatsAppID.sendReviewToKoorMeng,
                        variables: {
                            nama_koor: task.originator[i].username,
                            nama_engineer: name,
                            nama_report: taskName || task.taskName,
                            nama_project: projectName || task.projectName,
                        },
                        file: 'unfile',
                    })
                }

                toast({
                    title: 'Whatsapp sent successfully',
                    description: 'Task has been sent to Koordinator and Manager Engineering',
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                    position: 'top',
                })
            }
        }
    }

    const ModalConfirm = () => {
        return (
            <Modal isOpen={isConfirm} onClose={onConfirmClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Laporan Selesai</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text
                            fontSize={"18px"}
                        >
                        Apakah anda yakin untuk menyelesaikan laporan ini,
                        jika tidak click “DRAFT”, jika laporan selesai pilih “Confirm
                        </Text>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button
                                colorScheme="green"
                                onClick={() => handleUpdateTask("Confirm")}
                                isDisabled={isLoad}
                                isLoading={isLoad}
                            >
                                Confirm
                            </Button>
                            <Button
                                colorScheme="blue"
                                onClick={() => handleUpdateTask("Draft")}
                                isDisabled={isLoad}
                                isLoading={isLoad}
                            >
                                Draft
                            </Button>

                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        )
    }
    const ModalApprove = () => {
        return (
            <Modal isOpen={isApproveOpen} onClose={onApproveClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Approve Report</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text
                            fontSize={"18px"}
                        >Are you sure? You can’t undo this action afterwards.</Text>
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button
                                colorScheme="green"
                                onClick={onApproveHandler}
                                isDisabled={isLoad}
                                isLoading={isLoad}
                            >
                                Yes
                            </Button>
                            <Button
                                colorScheme="red"
                                onClick={onApproveClose}
                                isDisabled={isLoad}
                                isLoading={isLoad}
                            >
                                No
                            </Button>

                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        )
    }

    const ModalRevision = () => {
        return (
            <Modal isOpen={isRevisionOpen} onClose={onRevisionClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Note Revision</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {["Revision I", "Revision II"].includes(task.statusTask) && (
                            <Text
                                fontSize={"18px"}
                                fontStyle={"italic"}
                                mb={4}
                            >Ini adalah Terakhir & Update selanjutnya oleh Engineering akan langsung Completed</Text>
                        )}
                        <Textarea
                            placeholder="Note Revision"
                            size="md"
                            resize="none"
                            autoFocus
                            value={revisionNotes}
                            onChange={(e) => setRevisionNotes(e.target.value)}
                        />
                        <>
                            {attachmentRevisi?.map((file, index) => (
                                <HStack
                                    spacing={4}
                                    align="start"
                                    justifyContent={"space-between"}
                                    mt={4}
                                    key={index}
                                >
                                    <InputGroup>
                                        <Input
                                            type="file"
                                            placeholder="Attachment Revisi"
                                            autoFocus
                                            cursor={"pointer"}
                                            onChange={(e) => {
                                                const files = uploadImage(e.target.files[0] ?? null)
                                                    .then((res) => {
                                                        return setAttachmentRevisi((prev) => [...prev, res])
                                                    })
                                                    .catch((err) => {
                                                        console.error(err)
                                                    })

                                                return files;
                                            }}
                                        />
                                        <InputRightAddon>
                                            <Icon as={AiOutlineFileAdd} />
                                        </InputRightAddon>
                                    </InputGroup>
                                    <Button
                                        colorScheme="whatsapp"
                                        onClick={() => {
                                            setAttachmentRevisi((prev) => [...prev, ''])
                                        }}
                                    >+</Button>
                                    <Button
                                        bg="red.500"
                                        textColor={"white"}
                                        _hover={{ bg: "red.600" }}
                                        onClick={() => handleRemoveAttachmentRevisi(index)}
                                    >x</Button>
                                </HStack>
                            ))}
                        </>
                        {(attachmentRevisi.length === 0) && (
                            <HStack
                                spacing={4}
                                align="start"
                                justifyContent={"space-between"}
                                mt={4}
                            >
                                <Text
                                    fontSize={"14px"}
                                    textOverflow={"ellipsis"}
                                    overflow={"hidden"}
                                    color={"gray.500"}
                                >
                                    Tambahkan revisi attachment
                                </Text>
                                <Button
                                    colorScheme="whatsapp"
                                    onClick={() => {
                                        setAttachmentRevisi((prev) => [...prev, ''])
                                    }}
                                >+</Button>
                            </HStack>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <HStack>
                            <Button
                                colorScheme="whatsapp"
                                onClick={onRevisionHandler}
                                isDisabled={isLoad}
                                isLoading={isLoad}
                            >
                                Confirm
                            </Button>
                            <Button
                                onClick={onRevisionClose}
                                isDisabled={isLoad}
                                isLoading={isLoad}
                            >
                                Cancel
                            </Button>

                        </HStack>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        )
    }

    if (isManager || isKoordinator || isAdmin) {
        const filterAttachment = task?.attachment.filter((file) => file !== "")
        return (
            <>
                <HStack>
                    <Text
                        fontSize="medium"
                        fontWeight="bold"
                        color="gray.800"
                        mb={4}
                        as="b"
                    >Task</Text>
                    <Text
                        fontSize="medium"
                        color="gray.500"
                        mb={4}
                        fontWeight="600"
                    >/ Task Detail / {task.taskName}</Text>
                </HStack>
                <VStack
                    align="start"
                    w="100%"
                >
                    <Box
                        p={6}
                        mt={4}
                        mb={4}
                        borderRadius="md"
                        boxShadow="md"
                        bg="white"
                        w="100%"
                    >
                    <Text
                        fontSize="xl"
                        color="gray.800"
                        fontWeight="600"
                        mb={4}
                    >Project Description</Text>
                    <Stack pl={8} pr={8} mt={4} mb={4}>
                        <Text fontSize="medium" fontWeight="bold">Nama Proyek :</Text>
                        <Text>{task?.taskName}</Text>
                    </Stack>
                    <Stack pl={8} pr={8} mt={4} mb={4}>
                        <Text fontSize="medium" fontWeight="bold">Lokasi Proyek :</Text>
                        <Text>{task?.projectLocation}</Text>
                    </Stack>
                    <Stack pl={8} pr={8} mt={4} mb={4}>
                        <Text fontSize="medium" fontWeight="bold">Tipe Proyek :</Text>
                        <Text>{task?.projectType}</Text>
                    </Stack>
                    <Stack pl={8} pr={8} mt={4} mb={4}>
                        <Text fontSize="medium" fontWeight="bold">Nama Report :</Text>
                        <Text>{task?.projectName}</Text>
                    </Stack>
                    {task?.descriptionGeneral && (
                        <Stack pl={8} pr={8} mt={4} mb={4}>
                        <Text fontSize="medium" fontWeight="bold">Data Umum Proyek / Pendahuluan :</Text>
                        <Text>{task?.descriptionGeneral && parse(task?.descriptionGeneral)}</Text>
                        </Stack>
                    )}
                    {task?.planAnalysis && (
                        <Stack  pl={8} pr={8} mt={4} mb={4}>
                        <Text fontSize="medium" fontWeight="bold">Lingkup Pekerjaan :</Text>
                        <Text>{task?.planAnalysis && parse(task?.planAnalysis)}</Text>
                        </Stack>
                    )}
                    {task?.kriteriaPerencaan && (
                        <Stack  pl={8} pr={8} mt={4} mb={4}>
                        <Text fontSize="medium" fontWeight="bold">Kriteria Perencanaan :</Text>
                        <Text p={4}>{task?.kriteriaPerencaan && parse(task?.kriteriaPerencaan)}</Text>
                        </Stack>
                    )}
                    {task?.standardUsed.length > 0 && (
                        <Stack  pl={8} pr={8} mt={4} mb={4}>
                        <Text fontSize="medium" fontWeight="bold">Standar Code :</Text>
                        <Text>{task?.standardUsed?.map((standard, index) => (
                            `${standard.text}${index === task.standardUsed.length - 1 ? "" : ", "}`
                        ))}</Text>
                        </Stack>
                    )}
                    {task?.formulation?.length > 0 && (
                        <Stack  pl={8} pr={8} mt={4} mb={4}>
                        <Text fontSize="medium" fontWeight="bold">Data Acuan :</Text>
                        <Text>{task?.formulation?.map((formulation, index) => (
                            `${formulation.text}${index === task.formulation.length - 1 ? "" : ", "}`
                        ))}</Text>
                        </Stack>
                    )}
                    {task?.analysis?.length > 0 && (
                        <Stack  pl={8} pr={8} mt={4} mb={4}>
                        <Text fontSize="medium" fontWeight="bold">Analisis :</Text>
                        {task?.analysis && Array.isArray(task.analysis) && task.analysis.map((item, index) => (
                            <Stack key={index} mr="1">
                                <Text fontWeight="bold">{item.analysisName}</Text>
                                <Text>{parse(item.analysis)}</Text>
                            </Stack>
                        ))}
                        </Stack>
                    )}
                    {task?.resume?.length > 0 && (
                        <Stack  pl={8} pr={8} mt={4} mb={4}>
                        <Text fontSize="medium" fontWeight="bold">Resume :</Text>
                        {task?.resume && Array.isArray(task.resume) && task.resume.map((item, index) => (
                            <Stack key={index} mr="1">
                                <Text fontWeight="bold">{item.resumeName}</Text>
                                <Text>{parse(item.resume)}</Text>
                            </Stack>
                        ))}
                        </Stack>
                    )}
                    {task?.conclusion && (
                        <Stack  pl={8} pr={8} mt={4} mb={4}>
                        <Text fontSize="medium" fontWeight="bold">Kesimpulan & Saran :</Text>
                        <Text>{task?.conclusion && parse(task?.conclusion)}</Text>
                        </Stack>
                    )}
                    {task?.attachment.length > 0 && (
                        <Stack  pl={8} pr={8} mt={4} mb={4}>
                        <Text fontSize="medium" fontWeight="bold">Lampiran :</Text>
                        {filterAttachment.map((file, index) => (
                                <Text
                                    fontSize={"medium"}
                                    color="blue.500"
                                    onClick={() => {
                                    window.open(pathFile(file), "_blank")
                                    }}
                                    key={index}
                                    cursor="pointer"
                                >
                                    {index === 0 ? `${index + 1}.` : " "} {file && `${file}${index === filterAttachment.length - 1 ? "" : ", "}`}
                                </Text>
                                ))}
                        </Stack>
                    )}
                    {task?.report?.find((update) => ["Revision I", "Revision II"].includes(update.status)) && (
                        <Stack  pl={8} pr={8} mt={4} mb={4}>
                        <Text fontSize="medium" fontWeight="bold">Revisi Notes :</Text>
                        {task?.report?.map((update, i) => update.notes && (
                            <Stack key={i}>
                            {!update.attachment && update.attachment.length === 0 ? (
                                <Text>
                                {update.status} - {update.notes}
                                </Text>
                            ) : (
                                <Stack
                                flexDirection={"row"}
                                >
                                <Stack>
                                    <Text as="b">
                                    {update.status} :
                                    </Text>
                                </Stack>
                                <Stack>
                                    <Text>{update.notes}</Text>
                                    {update.attachment && update.attachment.filter((file) => file !== "").map((file, index) => (
                                    <Text
                                        fontSize={"medium"}
                                        color="blue.500"
                                        onClick={() => {
                                        window.open(pathFile(file), "_blank")
                                        }}
                                        key={index}
                                        cursor="pointer"
                                    >
                                        {index === 0 ? `${index + 1}.` : " "} {file && `${file}${index === update.attachment.length - 1 ? "" : ", "}`}
                                    </Text>
                                    ))}
                                </Stack>
                                </Stack>
                            )}
                            </Stack>
                        ))}
                        </Stack>
                    )}
                </Box>
                </VStack>
                {["Revision I", "Revision II" ,"Open", "Waiting Review"].includes(task.statusTask) && (
                    <>
                        <Spacer
                            mb={4}
                        />
                        <Box
                            p={6}
                            mt={4}
                            mb={4}
                            borderRadius="md"
                            boxShadow="md"
                            bg="white"
                        >
                            <HStack
                                spacing={4}
                                align="start"
                                justifyContent={"space-between"}
                            >
                                <Button
                                    w="100%"
                                    bg={"green.500"}
                                    color="white"
                                    onClick={onApprove}
                                    _hover={{ bg: "green.600" }}
                                >
                                    Approve
                                </Button>
                                <Button
                                    w="100%"
                                    bg={"orange.500"}
                                    color="white"
                                    onClick={onRevision}
                                    _hover={{ bg: "orange.600" }}
                                >
                                    {task.statusTask === "Revision II" ? "Revision III" : task.statusTask === "Revision I" ? "Revision II" : "Revision I"}
                                </Button>
                            </HStack>
                        </Box>
                    </>
                )}
                {ModalApprove()}
                {ModalRevision()}
            </>
        )
    }

    return (
        <>
        <HStack>
            <Text
                fontSize="x-large"
                color="gray.800"
                fontWeight="300"
                mb={4}
            >Task 
            </Text>
            <Text
                fontSize="x-large"
                color="gray.800"
                mb={4}
                fontWeight="600"
            >/ {task.taskName}</Text>
        </HStack>
        <Box
            p={6}
            mt={4}
            mb={4}
            borderRadius="md"
            boxShadow="md"
            bg="white"
        >
            <FormControl id="projectName">
                <FormLabel>
                    Nama Proyek
                    <Tooltip
                        label="Isikan nama proyek"
                        aria-label="A tooltip"
                        placement='right'
                    >
                        <InfoOutlineIcon
                            ml={2}
                            color="gray.500"
                            w={4}
                            h={4}
                            cursor="pointer"
                            _hover={{ color: "gray.700" }}
                        />
                    </Tooltip>
                </FormLabel>
                <Input
                    type="text"
                    placeholder={task.projectName}
                    _placeholder={{ color: "black" }}
                    value={payload.projectName}
                    disabled
                    onChange={(e) => {
                        setPayload((prev) => ({
                            ...prev,
                            projectName: e.target.value,
                        }))
                    }}
                />
            </FormControl>
            <FormControl id="projectLocation" mt={4}>
                <FormLabel>
                    Lokasi Proyek
                    <Tooltip
                        label="Isikan lokasi proyek"
                        aria-label="A tooltip"
                        placement='right'
                    >
                        <InfoOutlineIcon
                            ml={2}
                            color="gray.500"
                            w={4}
                            h={4}
                            cursor="pointer"
                            _hover={{ color: "gray.700" }}
                        />
                    </Tooltip>
                </FormLabel>
                <Input
                    type="text"
                    placeholder={task.projectLocation}
                    _placeholder={{ color: "black" }}
                    value={payload.projectLocation}
                    disabled
                    onChange={(e) => {
                        setPayload((prev) => ({
                            ...prev,
                            projectLocation: e.target.value,
                        }))
                    }}
                />
            </FormControl>
            <FormControl id="projectType" mt={4}>
                <FormLabel>
                    Tipe Proyek
                    <Tooltip
                        label="Isikan tipe project (contoh: Pekerjaan Konstruksi, Pekerjaan Jalan, dll)"
                        aria-label="A tooltip"
                        placement='right'
                    >
                        <InfoOutlineIcon
                            ml={2}
                            color="gray.500"
                            w={4}
                            h={4}
                            cursor="pointer"
                            _hover={{ color: "gray.700" }}
                        />
                    </Tooltip>
                </FormLabel>
                <Input
                    type="text"
                    placeholder={task.projectType}
                    _placeholder={{ color: "black" }}
                    value={payload.projectType}
                    disabled
                    onChange={(e) => {
                        setPayload((prev) => ({
                            ...prev,
                            projectType: e.target.value,
                        }))
                    }}
                />
            </FormControl>
            <FormControl id="reportName" mt={4}>
                <FormLabel>
                    Nama Report
                    <Tooltip
                        label="Isikan nama report yang akan dibuat (contoh: Laporan Analisis, Laporan Survey, dll)"
                        aria-label="A tooltip"
                        placement='right'
                    >
                        <InfoOutlineIcon
                            ml={2}
                            color="gray.500"
                            w={4}
                            h={4}
                            cursor="pointer"
                            _hover={{ color: "gray.700" }}
                        />
                    </Tooltip>
                </FormLabel>
                <Input
                    type="text"
                    placeholder={task.taskName}
                    _placeholder={{ color: "black" }}
                    value={payload.taskName}
                    onChange={(e) => {
                        setPayload((prev) => ({
                            ...prev,
                            taskName: e.target.value,
                        }))
                    }}
                />
            </FormControl>
            <FormControl id="descriptionGeneral" mt={4}>
                <FormLabel>
                    Data Umum Proyek / Pendahuluan
                    <Tooltip
                        label="Isikan data umum project yang akan dilakukan (contoh: Deskripsi Umum, Tujuan, dll)"
                        aria-label="A tooltip"
                        placement='right'
                    >
                        <InfoOutlineIcon
                            ml={2}
                            color="gray.500"
                            w={4}
                            h={4}
                            cursor="pointer"
                            _hover={{ color: "gray.700" }}
                        />
                    </Tooltip>
                </FormLabel>
                <ReactQuill
                    theme="snow"
                    value={payload.descriptionGeneral || task.descriptionGeneral}
                    onChange={(value) => {
                        setPayload((prev) => ({
                            ...prev,
                            descriptionGeneral: value,
                        }))
                    }}
                    placeholder="General Description"
                    modules={editorModules}
                    formats={editorFormats}
                    bounds={'.app'}
                    style={{ height: payload.descriptionGeneral || task.descriptionGeneral ? "auto" : "420px" }}
                />                
            </FormControl>
            <FormControl id="analysisPlan" mt={20}>
                <FormLabel>
                    Lingkup Pekerjaan / Batasan
                    <Tooltip
                        label="Isikan lingkup pekerjaan yang akan dilakukan (contoh: Batasan, Metode, dll)"
                        aria-label="A tooltip"
                        placement='right'
                    >
                        <InfoOutlineIcon
                            ml={2}
                            color="gray.500"
                            w={4}
                            h={4}
                            cursor="pointer"
                            _hover={{ color: "gray.700" }}
                        />
                    </Tooltip>
                </FormLabel>
                <ReactQuill
                    theme="snow"
                    value={payload.planAnalysis || task.planAnalysis}
                    onChange={(value) => {
                        setPayload((prev) => ({
                            ...prev,
                            planAnalysis: value,
                        }))
                    }}
                    placeholder="Analysis Plan"
                    modules={editorModules}
                    formats={editorFormats}
                    bounds={'.app'}
                    style={{ height: payload.planAnalysis || task.planAnalysis ? "auto" : "420px" }}
                />                
            </FormControl>
            <FormControl id="analysisPlan" mt={20}>
                <FormLabel>
                    Kriteria Perencanaan
                    <Tooltip
                        label="Isikan lingkup pekerjaan yang akan dilakukan (contoh:Umur Perencanaan, Metode Analisa, Mutu Material, dll)"
                        aria-label="A tooltip"
                        placement='right'
                    >
                        <InfoOutlineIcon
                            ml={2}
                            color="gray.500"
                            w={4}
                            h={4}
                            cursor="pointer"
                            _hover={{ color: "gray.700" }}
                        />
                    </Tooltip>
                </FormLabel>
                <ReactQuill
                    theme="snow"
                    value={payload.kriteriaPerencaan || task.kriteriaPerencaan}
                    onChange={(value) => {
                        setPayload((prev) => ({
                            ...prev,
                            kriteriaPerencaan: value,
                        }))
                    }}
                    placeholder="Kriteria Perencanaan"
                    modules={editorModules}
                    formats={editorFormats}
                    bounds={'.app'}
                    style={{ height: payload.kriteriaPerencaan || task.kriteriaPerencaan ? "auto" : "420px" }}
                />                
            </FormControl>
            <FormControl id="standardUsed" mt={20}>
                <FormLabel>
                    Standar Code
                    <Tooltip
                        label="Isikan standar yang digunakan (contoh: SNI, ISO, dll)"
                        aria-label="A tooltip"
                        placement='right'
                    >
                        <InfoOutlineIcon
                            ml={2}
                            color="gray.500"
                            w={4}
                            h={4}
                            cursor="pointer"
                            _hover={{ color: "gray.700" }}
                        />
                    </Tooltip>
                </FormLabel>
                <ReactTags
                    tags={standardUsedTag || task.standardUsed}
                    suggestions={suggestions}
                    delimiters={delimiters}
                    handleDelete={handleStandardUsedDelete}
                    handleAddition={handleStandardUsedAddition}
                    handleDrag={handleStandardUsedDrag}
                    handleTagClick={handleStandardUsedClick}
                    inputFieldPosition="inline"
                    maxTags={15}
                    allowAdditionFromPaste
                    autocomplete
                />
            </FormControl>
            <FormControl id="formulationProblem" mt={4}>
                <FormLabel>
                    Data Acuan
                    <Tooltip
                        label="Isikan data acuan yang digunakan (contoh: Data, Referensi, dll)"
                        aria-label="A tooltip"
                        placement='right'
                    >
                        <InfoOutlineIcon
                            ml={2}
                            color="gray.500"
                            w={4}
                            h={4}
                            cursor="pointer"
                            _hover={{ color: "gray.700" }}
                        />
                    </Tooltip>
                </FormLabel>
                <ReactTags
                    tags={formulationTag || task.formulation}
                    suggestions={suggestionsFormulation}
                    delimiters={delimiters}
                    handleDelete={handleFormulationDelete}
                    handleAddition={handleFormulationAddition}
                    handleDrag={handleFormulationDrag}
                    handleTagClick={handleFormulationClick}
                    inputFieldPosition="inline"
                    maxTags={15}
                    allowAdditionFromPaste
                    autocomplete
                />
            </FormControl>
            <FormControl id="analysis" mt={4}>
                <FormLabel>
                    Analysis
                    <Tooltip
                        label="Isikan analisis yang dilakukan (contoh: Hasil Analisis, Kesimpulan, dll)"
                        aria-label="A tooltip"
                        placement='right'
                    >
                        <InfoOutlineIcon
                            ml={2}
                            color="gray.500"
                            w={4}
                            h={4}
                            cursor="pointer"
                            _hover={{ color: "gray.700" }}
                        />
                    </Tooltip>
                </FormLabel>
                {analysisData.map((data, index) => (
                    <Stack pt={8} pb={12} key={index}>
                        <HStack alignItems={"center"} justifyContent={"space-between"}>
                            <Input
                                type="text"
                                placeholder="Nama Analysis"
                                value={data.analysisName}
                                onChange={(e) => {
                                    const newData = analysisData.map((item, idx) => 
                                        idx === index ? { ...item, analysisName: e.target.value } : item
                                    );
                                    setAnalysisData(newData);
                                }}
                            />
                            <Button
                                colorScheme="whatsapp"
                                size={"sm"}
                                onClick={() => {
                                    const newData = [...analysisData, { analysisName: "", analysis: "" }];
                                    setAnalysisData(newData);
                                }}
                            >
                                +
                            </Button>
                            <Button
                                colorScheme="red"
                                size={"sm"}
                                onClick={() => {
                                    const newData = analysisData.filter((item, idx) => idx !== index);
                                    setAnalysisData(newData);
                                }}
                            >
                                x
                            </Button>
                        </HStack>
                        <ReactQuill
                            theme="snow"
                            value={data.analysis}
                            onChange={(value) => {
                                const newData = analysisData.map((item, idx) => 
                                    idx === index ? { ...item, analysis: value } : item
                                );
                                setAnalysisData(newData);
                            }}
                            placeholder="Analysis"
                            modules={editorModules}
                            formats={editorFormats}
                            bounds={'.app'}
                            style={{ height: data.analysis || task.analysis ? "auto" : "420px" }}
                        />
                    </Stack>
                ))}
                {analysisData.length === 0 && (
                    <HStack
                        spacing={4}
                        align="start"
                        justifyContent={"space-between"}
                        mt={12}
                    >
                        <Text
                            fontSize={"14px"}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            color={"gray.500"}
                        >
                            Tambahkan Analysis
                        </Text>
                        <Button
                            colorScheme="whatsapp"
                            onClick={() => {
                                setAnalysisData((prev) => [...prev, { analysisName: "", analysis: "" }])
                            }}
                        >+</Button>
                    </HStack>
                )}
            </FormControl>
            <FormControl id="conclusion" mt={20}>
                <FormLabel>
                    Resume
                    <Tooltip
                        label="Isikan resume dari analisis yang dilakukan (contoh: Hasil Analisis, Kesimpulan, dll)"
                        aria-label="A tooltip"
                        placement='right'
                    >
                        <InfoOutlineIcon
                            ml={2}
                            color="gray.500"
                            w={4}
                            h={4}
                            cursor="pointer"
                            _hover={{ color: "gray.700" }}
                        />
                    </Tooltip>
                </FormLabel>
                {resumeData.map((data, index) => (
                    <Stack pt={8} pb={12} key={index}>
                        <HStack
                            alignItems={"center"}
                            justifyContent={"space-between"}
                        >
                            <Input
                                type="text"
                                placeholder="Nama Resume"
                                value={data.resumeName}
                                onChange={(e) => {
                                    const newData = resumeData.map((item, idx) => 
                                        idx === index ? { ...item, resumeName: e.target.value } : item
                                    );
                                    setResumeData(newData);
                                }}
                            />
                            <Button
                                colorScheme="whatsapp"
                                size={"sm"}
                                onClick={() => {
                                    const newData = [...resumeData, { resumeName: "", resume: "" }];
                                    setResumeData(newData);
                                }}
                            >
                                +
                            </Button>
                            <Button
                                colorScheme="red"
                                size={"sm"}
                                onClick={() => {
                                    const newData = resumeData.filter((item, idx) => idx !== index);
                                    setResumeData(newData);
                                }}
                            >
                                x
                            </Button>
                        </HStack>
                        <ReactQuill
                            theme="snow"
                            value={data.resume}
                            onChange={(value) => {
                                const newData = resumeData.map((item, idx) => 
                                    idx === index ? { ...item, resume: value } : item
                                );
                                setResumeData(newData);
                            }}
                            placeholder="Resume"
                            modules={editorModules}
                            formats={editorFormats}
                            bounds={'.app'}
                            style={{ height: data.resume || task.resume ? "auto" : "420px" }}
                        />
                    </Stack>
                ))}
                {resumeData.length === 0 && (
                    <HStack
                        spacing={4}
                        align="start"
                        justifyContent={"space-between"}
                        mt={12}
                    >
                        <Text
                            fontSize={"14px"}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            color={"gray.500"}
                        >
                            Tambahkan Resume
                        </Text>
                        <Button
                            colorScheme="whatsapp"
                            onClick={() => {
                                setResumeData((prev) => [...prev, { resumeName: "", resume: "" }])
                            }}
                        >+</Button>
                    </HStack>
                )}
            </FormControl>
            <FormControl id="conclusion" mt={20}>
                <FormLabel>
                    Kesimpulan dan Saran
                    <Tooltip
                        label="Isikan kesimpulan dari analisis yang dilakukan (contoh: Rekomendasi, Tindak Lanjut, dll)"
                        aria-label="A tooltip"
                        placement='right'
                    >
                        <InfoOutlineIcon
                            ml={2}
                            color="gray.500"
                            w={4}
                            h={4}
                            cursor="pointer"
                            _hover={{ color: "gray.700" }}
                        />
                    </Tooltip>
                </FormLabel>
                <ReactQuill
                    theme="snow"
                    value={payload.conclusion || task.conclusion}
                    onChange={(value) => {
                        setPayload((prev) => ({
                            ...prev,
                            conclusion: value,
                        }))
                    }}
                    placeholder="Conclusion"
                    modules={editorModules}
                    formats={editorFormats}
                    bounds={'.app'}
                    style={{ height: "420px" }}
                />                
            </FormControl>
            <FormControl id="attachment" mt={20}>
                <FormLabel>
                    Attachment
                    <Tooltip
                        label="Tambahkan lampiran file yang diperlukan (contoh: Gambar, Dokumen, dll)"
                        aria-label="A tooltip"
                        placement='right'
                    >
                        <InfoOutlineIcon
                            ml={2}
                            color="gray.500"
                            w={4}
                            h={4}
                            cursor="pointer"
                            _hover={{ color: "gray.700" }}
                        />
                    </Tooltip>
                </FormLabel>
                {(attachment && attachment.length >= 1) || task.attachment.length >= 1 ? (
                    <>
                        {(attachment || task?.attachment)?.map((file, index) => (
                            <HStack
                                spacing={4}
                                align="start"
                                justifyContent={"space-between"}
                                mt={4}
                            >
                                <InputGroup>
                                    {file !== "" ? (
                                        <Link
                                            href={pathFile(file)}
                                            isExternal
                                        >
                                            <Text color={"black"}>{file}</Text>
                                        </Link>
                                    ) : (
                                        <Input
                                            type="file"
                                            placeholder="Attachment"
                                            autoFocus
                                            cursor={"pointer"}
                                            onChange={(e) => {
                                                const files = e.target.files[0] ?? null;
                                                if (files && files.type === 'application/pdf') {
                                                uploadImage(files)
                                                    .then((res) => {
                                                    setAttachment((prev) => [...prev, res]);
                                                    })
                                                    .catch((err) => {
                                                        console.error(err);
                                                    });
                                                } else {
                                                    toast({
                                                        title: 'Failed',
                                                        description: 'Failed hanya diizinkan format PDF .',
                                                        status: 'error',
                                                        duration: 9000,
                                                        isClosable: true,
                                                        position: 'top',
                                                    })
                                                    console.error('Only PDF files are allowed');
                                                }

                                                return files;
                                            }}
                                        />
                                    )}
                                    <InputRightAddon>
                                        <Icon as={AiOutlineFileAdd} />
                                    </InputRightAddon>
                                </InputGroup>
                                <Button
                                    colorScheme="whatsapp"
                                    onClick={() => {
                                        setAttachment((prev) => [...prev, ''])
                                    }}
                                >+</Button>
                                <Button
                                    bg="red.500"
                                    onClick={() => handleRemoveAttachment(index)}
                                    textColor={"white"}
                                >X</Button>
                            </HStack>
                        ))}
                    </>
                ) : (
                    <>
                        {attachment?.map((file, index) => (
                            <HStack
                                spacing={4}
                                align="start"
                                justifyContent={"space-between"}
                                mt={4}
                            >
                                <InputGroup>
                                    <Input
                                        type="file"
                                        placeholder="Attachment"
                                        autoFocus
                                        cursor={"pointer"}
                                        onChange={(e) => {
                                            const files = uploadImage(e.target.files[0] ?? null)
                                                .then((res) => {
                                                    return setAttachment((prev) => [...prev, res])
                                                })
                                                .catch((err) => {
                                                    console.error(err)
                                                })

                                            return files;
                                        }}
                                    />
                                    <InputRightAddon>
                                        <Icon as={AiOutlineFileAdd} />
                                    </InputRightAddon>
                                </InputGroup>
                                <Button
                                    colorScheme="whatsapp"
                                    onClick={() => {
                                        setAttachment((prev) => [...prev, ''])
                                    }}
                                >+</Button>
                                <Button
                                    bg="red.500"
                                    textColor={"white"}
                                    _hover={{ bg: "red.600" }}
                                    onClick={() => handleRemoveAttachment(index)}
                                >x</Button>
                            </HStack>
                        ))}
                    </>
                )}
                {(attachment.length === 0 || task.attachment.length === 0) && (
                    <HStack
                        spacing={4}
                        align="start"
                        justifyContent={"space-between"}
                        mt={4}
                    >
                        <Text
                            fontSize={"14px"}
                            textOverflow={"ellipsis"}
                            overflow={"hidden"}
                            color={"gray.500"}
                        >
                            Tambahkan attachment
                        </Text>
                        <Button
                            colorScheme="whatsapp"
                            onClick={() => {
                                setAttachment((prev) => [...prev, ''])
                            }}
                        >+</Button>
                    </HStack>
                )}
            </FormControl>
            <HStack mt="8">
                <Button
                    colorScheme="whatsapp"
                    onClick={() => {
                        setIsConfirm(true)
                    }}
                    width={36}
                >
                    Confirm
                </Button>
                <Button
                    variant="outline"
                    onClick={() => window.history.back()}
                    width={36}
                >
                    Cancel
                </Button>
            </HStack>
        </Box>
        {ModalConfirm()}
        </>
    )
}
export default DetailTask