import React from 'react';

const UtilsList = () => {
  return (
    <iframe
      src="https://ers-calculation.farrid.dev/dashboard"
      style={{ width: '100%', height: '100vh', border: 'none' }}
      title="Dashboard"
    ></iframe>
  );
};

export default UtilsList;