import { Box, Text, Input, Icon, Flex, TagLabel, InputGroup, InputLeftElement, InputRightElement, Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import { MdOutlineSearch } from "react-icons/md";
import { FaFilter } from "react-icons/fa";

const SearchBar = ({ placeholder = "", value = "", onChange = () => {}, onFilterChange = () => {}, isAddonFilter = false }) => {
    return (
        <InputGroup border={"1px"} borderColor={"#4763E4"} rounded={"10px"} mb={'14px'} zIndex={1}>
            <InputLeftElement>
                <Icon as={MdOutlineSearch} color={"rgb(75,75,75)"}/>
            </InputLeftElement>
            <Input
                type='tel'
                placeholder={`Search ${placeholder}`}
                value={value}
                onChange={onChange}
            />
            <InputRightElement>
                <Menu>
                    <MenuButton as={Button} variant="link">
                        <Icon as={FaFilter} color={"rgb(75,75,75)"}/>
                    </MenuButton>
                    {isAddonFilter && (
                        <MenuList>
                            <MenuItem onClick={() => onFilterChange("Open")}>Open</MenuItem>
                            <MenuItem onClick={() => onFilterChange("Revision I")}>Revision I</MenuItem>
                            <MenuItem onClick={() => onFilterChange("Revision II")}>Revision II</MenuItem>
                            <MenuItem onClick={() => onFilterChange("Waiting Review")}>Waiting Review</MenuItem>
                            <MenuItem onClick={() => onFilterChange("Completed")}>Completed</MenuItem>
                        </MenuList>
                    )}
                </Menu>
            </InputRightElement>
        </InputGroup>
    );
}

export default SearchBar;